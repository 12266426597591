import { settingsOperadoras, subdomainEnv } from './../operadoras-settings'

export const getValidSubdomain = (host?: string | null) => {
  let subdomain: string | null = null

  if (process.env.NODE_ENV === 'development' && host === 'localhost:3000') {
    return 'main'
  }

  if (!host && typeof window !== 'undefined') {
    host = window.location.host
  }
  if (host && host.includes('.')) {
    const candidate = host.split('.')[0]
    if (candidate && Object.prototype.hasOwnProperty.call(subdomainEnv, candidate)) {
      subdomain = candidate
    } else if (
      candidate === 'saludia' ||
      candidate.includes('acropolis') ||
      candidate === 'www'
    ) {
      subdomain = 'main'
    } else {
      subdomain = null
    }
  }
  return subdomain
}

export const generateCssFromTheme = (
  obj: Record<string, { DEFAULT: string; contrast: string }>,
): Record<string, string> => {
  const css: Record<string, string> = {}

  for (const key in obj) {
    css[`--color-primary-${key}`] = obj[key].DEFAULT
    css[`--color-contrast-${key}`] = obj[key].contrast
  }

  return css
}

export const getCurrentSettings = (subdomain: string) => {
  const mapSettings = subdomainEnv[subdomain]
  const currentSettings = settingsOperadoras[mapSettings]
  return currentSettings
}

export const validateSubdomain = (subdomain: string) => {
  return subdomain && Object.prototype.hasOwnProperty.call(subdomainEnv, subdomain)
}
